import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import typeIndex from './types/type_index'
import auth from './auth/auth'
import verticalMenu from './vertical-menu'
import states from './city/state'
import cities from './city/city'
import unity from './unities'
import opportunity from './opportunity/opportunity'
import menu from './menus'
import hiringTypes from './hiring-type/hiring-type'
import paymentMethods from './payment-method'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    typeIndex,
    states,
    cities,
    unity,
    opportunity,
    menu,
    hiringTypes,
    paymentMethods,
  },
  strict: process.env.DEV,
})
